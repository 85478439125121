import React from 'react';
import './Sidebar.css';

const Sidebar = ({ step, onStepChange }) => {
  const steps = [
    { number: 1, label: 'Select Database' },
    { number: 2, label: 'Enter Schemas' },
    { number: 3, label: 'Transformation Plan' },
    { number: 4, label: 'SQL Query' }
  ];

  return (
    <div className="sidebar">
      {steps.map(({ number, label }) => (
        <div
          key={number}
          className={`sidebar-item ${step === number ? 'active' : ''} ${step > number ? 'completed' : ''}`}
          onClick={() => step > number && onStepChange(number)}
        >
          {step > number ? '✓' : number}. {label}
        </div>
      ))}
    </div>
  );
};

export default Sidebar;
