import React, { useState } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/sql/sql';
import './SqlQueryInput.css';

const SqlQueryInput = ({ migrationPlan, databaseTech }) => {
  const [sqlQuery, setSqlQuery] = useState('');
  const [migratedQuery, setMigratedQuery] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSqlQueryChange = (editor, data, value) => {
    setSqlQuery(value);
  };

  // const apiUrl = process.env.REACT_APP_CHATBOT_API_URL;

  const handleMigrateQuery = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/migrate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          database_technology: databaseTech,
          migration_plan: JSON.stringify(migrationPlan),
          query: sqlQuery,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setMigratedQuery(data.query);
    } catch (error) {
      setError('Error migrating query. Please try again.');
      console.error('Error migrating query:', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="sql-query-input-container">
      <h2>Enter SQL Query</h2>
      <CodeMirror
        value={sqlQuery}
        options={{
          mode: 'sql',
          theme: 'material',
          lineNumbers: true,
        }}
        onBeforeChange={handleSqlQueryChange}
      />
      <button onClick={handleMigrateQuery} disabled={loading}>
        {loading ? 'Migrating...' : 'Migrate Query'}
      </button>
      {error && <p className="error-message">{error}</p>}
      {migratedQuery && (
        <div className="migrated-query-container">
          <h3>Migrated Query</h3>
          <CodeMirror
            value={migratedQuery}
            options={{
              mode: 'sql',
              theme: 'material',
              lineNumbers: true,
              readOnly: true,
            }}
          />
        </div>
      )}
    </div>
  );
};

export default SqlQueryInput;
