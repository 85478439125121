import React from 'react';
import './Header.css';

const Header = () => {
  return (
    <header className="header">
      <div className="header-content">
      <a href="https://chicory.ai"><img src={process.env.PUBLIC_URL + '/logo.png'} alt="Chicory" className="header-logo" /></a>
          <a href="https://calendly.com/haya-sridharan/30min" className="cta-button">Get Started</a>
      </div>
    </header>
  );
};

export default Header;
