import React from 'react';
import { ClipLoader } from 'react-spinners';
import './Spinner.css';

const Spinner = ({ message }) => {
  return (
    <div className="spinner-container">
      <ClipLoader size={50} color={"#007bff"} />
      <p>{message}</p>
    </div>
  );
};

export default Spinner;
