import React from 'react';
import './ChangesTable.css';

const ChangesTable = ({ changes, setChanges, nextStep }) => {
  const handleInputChange = (path, value) => {
    const keys = path.split('.');
    const newChanges = { ...changes };
    let current = newChanges;

    keys.forEach((key, index) => {
      if (index === keys.length - 1) {
        current[key] = value;
      } else {
        current = current[key];
      }
    });

    setChanges(newChanges);
  };

  const renderTables = (changes) => {
    return Object.entries(changes.schemaChanges).map(([tableName, tableData]) => (
      <div key={tableName} className="table-container">
        <h3>{tableName}</h3>
        <table className="change-table">
          <thead>
            <tr>
              <th>Column Name</th>
              <th>Change Type</th>
              <th>Transformation Details</th>
              <th>Migration Example</th>
            </tr>
          </thead>
          <tbody>
            {Object.entries(tableData.columns).map(([columnName, columnData]) => (
              <tr key={columnName}>
                <td>{columnName}</td>
                <td>{columnData.changes.changeType}</td>
                <td>
                  <input
                    type="text"
                    value={columnData.changes.transformationDetails}
                    onChange={(e) => handleInputChange(`schemaChanges.${tableName}.columns.${columnName}.changes.transformationdetails`, e.target.value)}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={columnData.changes.transformationExample}
                    onChange={(e) => handleInputChange(`schemaChanges.${tableName}.columns.${columnName}.changes.transformationExample`, e.target.value)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    ));
  };

  return (
    <div>
      <h2>Transformation Plan</h2>
      {renderTables(changes)}
      <button onClick={nextStep}>Next</button>
    </div>
  );
};

export default ChangesTable;
