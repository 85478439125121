import React, { useState, useEffect } from 'react';
import { Controlled as CodeMirror } from 'react-codemirror2';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/material.css';
import 'codemirror/mode/sql/sql';
import './SchemaInput.css';

const SchemaInput = ({ currentSchema, setCurrentSchema, newSchema, setNewSchema, nextStep }) => {
  const [currentSchemaValue, setCurrentSchemaValue] = useState(currentSchema);
  const [newSchemaValue, setNewSchemaValue] = useState(newSchema);

  useEffect(() => {
    setCurrentSchemaValue(currentSchema);
  }, [currentSchema]);

  useEffect(() => {
    setNewSchemaValue(newSchema);
  }, [newSchema]);

  const handleCurrentSchemaChange = (editor, data, value) => {
    setCurrentSchemaValue(value);
    setCurrentSchema(value);
  };

  const handleNewSchemaChange = (editor, data, value) => {
    setNewSchemaValue(value);
    setNewSchema(value);
  };

  return (
    <div className="schema-container">
      <h2>Enter Current and New Database Schema</h2>
      <div className="schema-input-section">
        <div className="schema-input">
          <h3>Current Schema</h3>
          <CodeMirror
            value={currentSchemaValue}
            options={{
              mode: 'sql',
              theme: 'material',
              lineNumbers: true
            }}
            onBeforeChange={handleCurrentSchemaChange}
          />
        </div>
        <div className="schema-input">
          <h3>New Schema</h3>
          <CodeMirror
            value={newSchemaValue}
            options={{
              mode: 'sql',
              theme: 'material',
              lineNumbers: true
            }}
            onBeforeChange={handleNewSchemaChange}
          />
        </div>
      </div>
      <button onClick={nextStep}>Next</button>
    </div>
  );
};

export default SchemaInput;
