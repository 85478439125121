import React from 'react';
import './DatabaseSelection.css';

const databases = [
  { name: 'Aurora MySQL', logo: 'database-type-aurora-mysql.png' },
  { name: 'Aurora PostgreSQL', logo: 'database-type-aurora-pg.png' },
  { name: 'BigQuery', logo: 'database-type-bigquery.png' },
  { name: 'Couchbase', logo: 'database-type-couchbase.svg' },
  { name: 'Elasticsearch', logo: 'database-type-elastic.svg' },
  { name: 'MariaDB', logo: 'database-type-mariadb.svg' },
  { name: 'MongoDB', logo: 'database-type-mongodb.svg' },
  { name: 'MySQL', logo: 'database-type-mysql.svg' },
  { name: 'Oracle', logo: 'database-type-oracle.svg' },
  { name: 'Percona', logo: 'database-type-percona.svg' },
  { name: 'PostgreSQL', logo: 'database-type-postgresql.svg' },
  { name: 'Redis', logo: 'database-type-redis.svg' },
  { name: 'Redshift', logo: 'database-type-redshift.png' },
  { name: 'Snowflake', logo: 'database-type-snowflake.svg' },
  { name: 'SQL Server', logo: 'database-type-sql-server.png' }
];

const DatabaseSelection = ({ setDatabase, nextStep }) => {
  const handleSelection = (db) => {
    setDatabase(db);
    nextStep();
  };

  return (
    <div>
      <h2>Select a Database Technology</h2>
      <div className="database-selection">
        {databases.map((db) => (
          <div key={db.name} className="database-item" onClick={() => handleSelection(db.name)}>
            <img src={process.env.PUBLIC_URL + '/' + db.logo} alt={db.name} className="database-logo" />
            <span>{db.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default DatabaseSelection;
