import React, { useState } from 'react';
import DatabaseSelection from './components/query-migration/DatabaseSelection';
import SchemaInput from './components/query-migration/SchemaInput';
import ChangesTable from './components/query-migration/ChangesTable';
import SqlQueryInput from './components/query-migration/SqlQueryInput';
import Sidebar from './components/query-migration/Sidebar';
import Spinner from './components/reusable/Spinner';
import Header from './components/reusable/Header';
import Footer from './components/reusable/Footer';
import './App.css';

const App = () => {
  const [step, setStep] = useState(1);
  const [database, setDatabase] = useState(null);
  const [currentSchema, setCurrentSchema] = useState('');
  const [newSchema, setNewSchema] = useState('');
  const [changes, setChanges] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // const apiUrl = process.env.REACT_APP_CHATBOT_API_URL;

  const handleNextStep = () => {
    if (step === 2) {
      // Make the API call to get changes data
      fetchChangesData();
    } else {
      setStep(step + 1);
    }
  };

  const fetchChangesData = async () => {
    setLoading(true);
    setError(null);
    try {
      const response = await fetch('/api/migrate-plan', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          database_technology: database,
          old_schema: currentSchema,
          new_schema: newSchema
      }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setChanges(data);
      setStep(step + 1);
    } catch (error) {
      setError('Error fetching changes data. Please try again.');
      console.error('Error fetching changes data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleStepChange = (step) => {
    setStep(step);
  };

  const handleCloseError = () => {
    setError(null);
  };

  return (
    <div className="app">
      <Header className="header" />
      <div className="main-content">
      <Sidebar step={step} onStepChange={handleStepChange} />
        {loading && <Spinner message="Chicory is identifying changes..." />}
        {error && (
            <div className="error-message">
              {error}
              <button className="close-button" onClick={handleCloseError}>
                &times;
              </button>
            </div>
          )}        {!loading && step === 1 && <DatabaseSelection setDatabase={setDatabase} nextStep={handleNextStep} />}
        {!loading && step === 2 && (
          <SchemaInput
            currentSchema={currentSchema}
            setCurrentSchema={setCurrentSchema}
            newSchema={newSchema}
            setNewSchema={setNewSchema}
            nextStep={handleNextStep}
          />
        )}
        {!loading && step === 3 && changes && <ChangesTable changes={changes} setChanges={setChanges} nextStep={handleNextStep} />}
        {!loading && step === 4 && <SqlQueryInput migrationPlan={changes} databaseTech={database}/>}
      </div>
      <Footer className="footer"/>
    </div>
  );
};

export default App;
